@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');
* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.your-style {
  font-family: 'Maelstrom';
  font-weight: bold;
  font-style: normal;
}

.disabled-link {
  pointer-events:none !important; 
  opacity:0.6 !important;       
}




@font-face {
  font-family: "Maelstrom";
  src: url("./custom/Maelstrom-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

main {
  min-height: 85vh;
}


.whiteFont{
  color: white !important;
}