.subpageHeading{
    font-size: 300%;
    font-weight: 200;
}
.subSubHeading{
    font-size: 150%;
    font-weight: 200;
}
.effectText{
    background: white;

  color: #4056a1 !important;
  border-radius: 32px;
  font-size:80%;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}

.crazyNumbers2{
    font-size: 300%;
}

.carousel {
    border-radius: 32px 32px 32px 32px !important;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 10px 20px -12px;
    display:block;
    opacity:0.75
  }
  .crousel-item{
    opacity:0;
    transition: opacity 1s !important;
  }
  .carousel-item.active{
    opacity:0.5;
    transition: opacity 1s !important;
  }
  .carousel-inner {
    border-radius: 32px 32px 32px 32px !important;
  }
  .carousel-control-next-icon {
    width:0 !important;
  }
  .carousel-control-prev-icon {
    width:0 !important;
  }

  .carousel-control-next{
    border-radius: 32px !important;
  }



  @media only screen and (max-width: 600px) {
    .subpageHeading{
        font-size: 150%;
        text-align: center;
    }
    .subSubHeading{
        font-size: 100%;
        text-align: center !important;
    }
    .columnMedia{
        text-align: center !important;
    }
  }