.navbar {
  outline: none;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.card:hover .highlightText{
  background-color:#4056a1;
  color:white;
  border-radius: 30px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top:1px;
  padding-bottom:1px;
  margin:1px;
  transition: 0.3s ease-in-out;
  opacity: 1 !important
}
.homeNav {
  background-color: white !important;
  color:#4056a1 !important;
}
.homeNav:hover{
  transform: scale(1.4);
  transition:all 0.3s ease-in-out;
}
.baseSubHeading {
  font-size: 80%;
}
.highMarginTop {
  margin-top: 10rem;
}
.highMarginBottom {
  margin-bottom: 10rem;
}
.highMarginRow {
  margin-top: 5rem;
  margin-bottom: 8rem;
}
.baseHeading {
  font-size: 300%;
  color: #4056a1;
  text-decoration: none;
  text-transform: none !important;
}
.fullRow {
  margin: 0 !important;
}


.latestVentures{
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  transition: all 0.5s ease-in-out;
}


.glassBtn{
  background-color: transparent !important;
  border-radius:30px !important;
  outline: none !important;
  border:2px solid white;
  padding:0.5rem 2rem 0.5rem 2rem;
  color: white;
  transition: all 0.3s ease-in-out;
}
.glassBtn:hover{
  background-color: white!important;
  border-radius:30px !important;
  outline: none !important;
  border:2px solid white;
  padding:0.5rem 2rem 0.5rem 2rem;
  color: #4056a1;
}
.glassBtn:active{
  background-color: white!important;
  border-radius:30px !important;
  outline: none !important;
  border:2px solid white;
  padding:0.5rem 2rem 0.5rem 2rem;
  color: #4056a1;
}
.glassBtn:focus{
  background-color: white!important;
  border-radius:30px !important;
  outline: none !important;
  border:2px solid white;
  padding:0.5rem 2rem 0.5rem 2rem;
  color: #4056a1;
  box-shadow: none !important;
}


.baseButton {
  background-color: #4056a1 !important;
  border-radius: 32px;
  color: white !important;
  outline: none !important;
  border: 2px solid #4056a1;
}
.baseButton:hover {
  background-color: white !important;
  border-radius: 32px;
  color: #4056a1 !important;
  outline: none !important;
  border: 2px solid #4056a1;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}
.baseButton:focus {
  background-color: #4056a1 !important;
  border-radius: 32px;
  color: white !important;
  outline: none !important;
  border: 2px solid #4056a1;
  box-shadow: none !important;
}

.secondaryButton {
  background-color: white !important;
  border-radius: 32px;
  color: #4056a1 !important;
  outline: none !important;
  border: 2px solid #4056a1 !important;
}
.secondaryButton:hover {
  background-color: #4056a1 !important;
  border-radius: 32px;
  color: white !important;
  outline: none !important;
  border: 2px solid #4056a1;
  transition: all 0.2s ease-in-out;
}
.secondaryButton:focus {
  background-color: white !important;
  border-radius: 32px;
  color: #4056a1 !important;
  outline: none !important;
  border: 2px solid #4056a1 !important;
  box-shadow: none !important;
}
.secondaryButton:active {
  background-color: white !important;
  border-radius: 32px;
  color: #4056a1 !important;
  outline: none !important;
  border: 2px solid #4056a1 !important;
  box-shadow: none !important;
}
.secondaryButton2 {
  background-color: none !important;
  padding:2px !important;
  margin:2px !important;
  border-radius: 32px;
  color: #4056a1 !important;
  outline: none !important;
  border: 2px solid #4056a1 !important;
}
.secondaryButton2:hover {
  background-color: #4056a1 !important;
  border-radius: 32px;
  color: white !important;
  outline: none !important;
  border: 2px solid #4056a1;
  transition: all 0.2s ease-in-out;
}
.secondaryButton2:focus {
  background-color: #4056a1 !important;
  border-radius: 32px;
  color: white !important;
  outline: none !important;
  border: 2px solid #4056a1;
}
.baseCard {
  border-radius: 32px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border: none !important;
}
.crazyNumbers {
  font-family: "Maelstrom";
  font-size: 9rem;
  font-weight: bold;
  font-style: normal;
}
.baseCard:hover {
  border-radius: 32px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  transform: scale(1.05) !important;
  color: hsla(226, 43%, 44%, 1);

  color: linear-gradient(
    45deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );

  color: -moz-linear-gradient(
    45deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );

  color: -webkit-linear-gradient(
    45deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#4056A1", endColorstr="#58B3CC", GradientType=1 );
  transition: all 0.5s ease-in-out;
  outline: none;
  border: none;
}
.baseCardTitle {
  letter-spacing: 2px !important;
  font-weight: 600;
  color: #4056a1;
}
.baseCardText {
  letter-spacing: 1px !important;
  font-weight: 200;
  font-size: 80%;
  color: grey;
}
.navbarLogo {
  max-width: 75px !important;
}

.navbarTitle {
  font-size: 120%;
}
.navbarLinkuiux {
  border-radius: 25px !important;
}

.navbarLinkuiux:hover {
  border-radius: 25px !important;
  background-color: #4056a1 !important;
  color: white !important;
  transition: all 0.4s ease-in-out;
}

.navbarLink:hover {
  color: #4056a1 !important;
  transition: all 0.3s ease-in-out !important;
}
.homeIntro {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.whiteBg{
  background-color: #fff;
}
.roundedCorners{
  border-radius:30px
}
.backGrad {
  background: hsla(226, 43%, 44%, 1);

  background: linear-gradient(
    225deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );

  background: -moz-linear-gradient(
    225deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );

  background: -webkit-linear-gradient(
    225deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );
}
.homeIntroSecondColumn {
}
.homeHello {
  color: #4056a1;
  font-size: 700%;
  font-weight: 600;
  padding-bottom: 0;
  top: 0;
}
.beginningText {
  background: hsla(226, 43%, 44%, 1);

  background: linear-gradient(
    45deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );

  background: -moz-linear-gradient(
    45deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );

  background: -webkit-linear-gradient(
    45deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#4056A1", endColorstr="#58B3CC", GradientType=1 );
  color: white !important;
  border-radius: 32px;
}

.gradBg{
  background-color: #D9AFD9;
background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%);

}

.roundedCorners{
  border-radius: 30px;
}
.gradText {
  color: hsla(226, 43%, 44%, 1);

  color: linear-gradient(
    45deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );

  color: -moz-linear-gradient(
    45deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );

  color: -webkit-linear-gradient(
    45deg,
    hsla(226, 43%, 44%, 1) 0%,
    hsla(193, 53%, 57%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#4056A1", endColorstr="#58B3CC", GradientType=1 );
}

.homeNavigate {
  font-size: 250%;
  font-weight: 600;
}

.projectsTitle {
  font-size: 200%;
  font-weight: 600;
}
.numberOf {
  border-radius: 50%;
  font-size: 100%;
  font-weight: 600;
  background-color:#4056a1;
  border-radius: 30px;
  margin-left:1rem;
  margin-right:1rem;
  padding:10px;
  color:white
}
.numberOfType {
}
.quote {
  padding-left: 0 !important;
  font-size: 180%;
  color: #4056a1;
}

.titleFont{
  font-size: 150%;
  font-weight: 600;
}
.titleLargerFont{
  font-size:300%
}
.titleLargerFontSide{
  font-size:300%
}
.whiteText {
  color: white;
}
.dullWhiteText {
  color: rgb(219, 214, 214);
}

.socialLogo:hover {
  transform: scale(1.5);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #4056a1;
}

.smallText {
  font-size: 60%;
}

.footer {
  background-color: #9fb1ec;
}

.footerOverall{
  background-color: #e6ebfa;
  font-size:70%;
  margin-top:20vh !important;
  
}

.footerHeader{
    font-size:120%;
    
}


.footerSubLinks{
    text-decoration: none !important;
   left:0;
}

.footerSubLinks:hover{
    font-weight: 600;
}


.footerCopyrights{
    font-size:90% !important;
}

.footerLogo{
    max-height: 150px;
}

.footerExia{
    font-weight:700;
    text-decoration: none;
}

.heart:hover{
    color: #c51104;
    font-size: 150%;
    
}


.glassBg{
  /* From https://css.glass */
/* From https://css.glass */
/* From https://css.glass */
/* From https://css.glass */
/* From https://css.glass */
/* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 30px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
border: 1.5px solid rgba(255, 255, 255, 0.5);
}
.glassBg:hover{
  
  transform: scale(1.05) !important;
  transition: all 0.5s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.glassBg2{
  /* From https://css.glass */
/* From https://css.glass */
/* From https://css.glass */
/* From https://css.glass */
/* From https://css.glass */
/* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 30px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
border: 1.5px solid rgba(255, 255, 255, 0.5);
}
.glassBg2:hover{
  transition: all 0.5s ease-in-out;
}

.homeSecondCol{
  border-radius: 30px;
}
.smallerFont{
  font-size: 70%;
}

@media only screen and (max-width: 600px) {
  .titleLargerFont{
    font-size:120%;
    font-weight:600;
    margin-top: 0 !important;
    text-align:center;
  }
  .titleLargerFontSide{
    font-size:120%;
    font-weight:600;
    margin-top: 0 !important;
    text-align:left;
  }
  .mediaSmallerFont{
    font-size: 80%;
  }
  .descText{
    font-size:90%;
    margin-top:0 !important;
  }
  .baseCard{
    margin-top:2rem;
    margin-left:1rem;
    margin-right:1rem;
  }
  .glassBg{
    margin-top:2rem;
    margin-left:1rem;
    margin-right:1rem;
  }
  .highMarginRow{
    margin-top:2rem !important;
  }
  .crazyNumbers{
    font-size: 400%;
  }
  .cardImage{
    max-width: 75px;
  }
  .quote{
    margin-top:1rem;
  }
  .mediaSpace{
    line-height: 2;
  }
  .centerMedia{
    text-align: center;
  }
  .highMarginBottom{
    margin-bottom: 0rem !important;
  }
  .screenMargin{
    margin-top:3rem !important;
  }
  .disappear{
    font-size: 0;
  }
  .navbarLogo {
    max-width: 50px !important;
  }
  .marginTopOnXs{
    margin-top:2rem !important
  }
  .marginXonXs{
    margin-left:1rem;
    margin-right:1rem
  }
  .veryHighMarginTopOnXs{
    margin-top:10rem
  }
}